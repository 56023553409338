/*------------------------------------*\
    $Form Elements
\*------------------------------------*/

.form-login{
  max-width: 680px;;
  margin:0.5em auto 0 auto;
  input{padding: $space-quar; }

}

.onoffswitch,.yesnoswitch {
  position: relative; width: auto;
  min-width: 86px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
  @extend .d-flex; @extend .flex-row; @extend .align-items-end; @extend .justify-content-end;
  &.is-invalid{
    color: $red;
    .yesnoswitch-label,.yesnoswitch-inner:after{border-color:$red; color: $white;}
  }
}
.darkmodeswitch{
  @extend .yesnoswitch;
  min-width: 94px;
}

.onoffswitch-checkbox,.yesnoswitch-checkbox,.darkmodeswitch-checkbox {
  font-size: 0;
  opacity:0;
  filter:alpha(opacity=0);
}

.onoffswitch-label,.yesnoswitch-label,.darkmodeswitch-label {
  display: block; overflow: hidden; cursor: pointer;
  border: 2px solid #999999; border-radius: 5px;
}

.onoffswitch-inner,.yesnoswitch-inner,.darkmodeswitch-inner {
  display: block; width: 200%; margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after,.yesnoswitch-inner:before, .yesnoswitch-inner:after,.darkmodeswitch-inner:before,.darkmodeswitch-inner:after {
  display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
  font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: $cyan; color: #FFFFFF;
  text-align: left;
}

.yesnoswitch-inner:before {
  content: "YES";
  padding-left: 10px;
  @extend .bg-primary;
  color: #FFFFFF;
  text-align: left;
}
.darkmodeswitch-label{
  margin-bottom: 0;
}
.darkmodeswitch-inner{
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  @extend .justify-content-between;
}
.darkmodeswitch-inner.active .before, .darkmodeswitch-inner .before{
  padding: 2px 2px 2px 20px;
  background-color: $cyan; color: #FFFFFF;
  text-align: left;
  width: 100%;
  svg{font-size: 1.4em; margin-top:2px;}
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: $grey-light; color: #999999;
  text-align: right;
}

.yesnoswitch-inner:after {
  content: "NO";
  padding-right: 10px;
  background-color: $grey-light; color: #999999;
  text-align: right;
}

.darkmodeswitch-inner .after {
  padding: 2px 20px 2px 2px;
  background-color: $grey-light; color: #999999;
  text-align: right;
  width: 100%;
  svg{font-size: 1.4em; margin-top:2px;}
}

.onoffswitch-switch, .yesnoswitch-switch,.darkmodeswitch-switch{
  display: block; width: 20px; margin: 6px 2px 0 0;
  background: #FFFFFF;
  position: absolute; top: -2px; bottom: 6px;
  right: 40px;
  border: 2px solid #999999;
  border-radius: 5px;
  padding:0.2em 0.7em;
  transition: all 0.3s ease-in 0s;
}

.yesnoswitch-switch{
  padding:0.7em 0.7em;
}

.darkmodeswitch-switch{
  margin:7px 0 0;
  padding:0.7em 0.7em;
  right:48px
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.yesnoswitch-checkbox:checked + .yesnoswitch-label .yesnoswitch-inner {
  margin-left: 0;
}
.yesnoswitch-checkbox:checked + .yesnoswitch-label .yesnoswitch-switch {
  right: 0px;
}

.darkmodeswitch-checkbox:checked + .darkmodeswitch-label .darkmodeswitch-inner {
  margin-left: 0;
}

.darkmodeswitch-checkbox:checked + .darkmodeswitch-label .darkmodeswitch-switch {
  right: 5px;
}

/* class applies to select element itself, not a wrapper element */
.select-input {
  @extend .d-flex;
  font-size: 14px;
  font-family: sans-serif;
  color: #444;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 220px;
  box-sizing: border-box;
  margin: 0;
  @extend .solid-1-line;
  @extend .border-charcoal;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  /* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient.
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
*/

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right .7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-form::-ms-expand {
  display: none;
}
/* Hover style */
.select-form:hover {
  border-color: #888;
}
/* Focus style */
.select-form:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}


/* Set options to normal weight */
.select-form option {
  font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
  background-position: left .7em top 50%, 0 0;
  padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-form:disabled, .select-css[aria-disabled=true] {
  color: graytext;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-form:disabled:hover, .select-css[aria-disabled=true] {
  border-color: #aaa;
}

.range-input {
  @extend .d-flex;
  @extend .flex-row;

  input[type="range"]{font-size: 0.2em; height: 4px; color: $grey-pestal; margin:4px 2em 1em; width:220px;  -webkit-appearance: none; background: $grey-pestal}
}

label.toggle-check{
position: relative;
}

.toggle-check-input {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index:2;
  visibility: hidden;
}

.toggle-check-text {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  background: #CCC;
  padding: 0.25em 0.5em 0.25em 2em;
  border-radius: 1em;
  min-width: 2em;
  color: #FFF;
  cursor: pointer;
  transition: background-color 0.15s;
}

.toggle-check-text:after {
  content: ' ';
  display: block;
  background: #FFF;
  width: 1.1em;
  height: 1.1em;
  border-radius: 1em;
  position: absolute;
  left: 0.3em;
  top: 0.25em;
  transition: left 0.15s, margin-left 0.15s;
}

.toggle-check-text:before {
  content: 'No';
}

.toggle-check-input:checked ~ .toggle-check-text {
  background: #8ad869;
  padding-left: 0.5em;
  padding-right: 2em;
}

.toggle-check-input:checked ~ .toggle-check-text:before {
  content: 'Yes';
}

.toggle-check-input:checked ~ .toggle-check-text:after {
  left: 100%;
  margin-left: -1.4em;
}


.login-pass{-webkit-text-security: disc; -moz-text-security: disc;}

.form-login-holder{
  @include ctm-breakpoint(720px){
    padding-left:1em;
    padding-right:1em;
  }
}
.error-message{display: none}
.fields.error textarea, .fields.error input, .field.error input, .field.error textarea, .holder input.error{
 background: $red;
  color: $white;
}

.fields.error{
  input{color: $white}
  .error-message{display: flex; flex:1; justify-content: flex-end; @extend .mt-3; color: $red; text-align: right; width: 100%;}
}

.field.error{
  input{color: $white}
  @extend .flex-column;
  .error-message{display: flex; flex:1; justify-content: flex-end; @extend .mt-3; color: $red; text-align: right}
}

.field.error ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.field.error :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

.field.error ::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}

.fields-group .select-input{
  @extend .btn-shadow;
}

.label-line-item{
  padding: 0.3em;
  font-weight: bold;
}

.input-line-item{@extend .w-100; max-width: 60px; padding: 0.3em; text-align: center}

input.input-line-item::-webkit-outer-spin-button,
input.input-line-item::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.input-line-item[type=number] {
  -moz-appearance: textfield;
}

.sig-preview{
  @extend .bg-white;
  @extend .p-3;
  @extend .m-auto;
  max-width: 520px;
  @include ctm-breakpoint(480px){
    max-width: 420px;
  }
  @include ctm-breakpoint(360px){
    max-width: 380px;
  }

}
.sign-component{
  @include pt(3);
  @extend .pb-3;
  @extend .bg-grey-bright;
  color: $charcoal;
  border-top:1px solid $white !important;
  border-bottom:1px solid $white !important;

  h3{ @extend .text-charcoal;}
  img{@extend .pb-3}
  p{margin-bottom: 0}

  @media screen and (max-height: 429px) and (orientation: landscape) {
    height: 101%;
    width: 101%;
    position: fixed;
    z-index: 20;
    left: -1px;
    top: -1px;
    //background: rgba(0,0,0, 0.7);
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0;
  }


  .btn-holders{
    justify-content: center !important;
  }

  .invalid-feedback {
    font-size: 1.25em;
    @include mb(3);
  }


  .sig-pad-holder {

    display: flex;
    flex-direction: column !important;


    .btn-holders {

      display: flex;

      @media screen and (max-width:600px) and (orientation: portrait) {
        display: none !important;
      }

      &.active {
        display: flex !important;
      }

    }
  }


  .sig-btn-holder{

    display: none;

    .orientation-message {
      color: $black !important;
    }

    @media screen and (max-width:600px) and (orientation: portrait) {
        display: flex !important;
    }

  }
    //  display: none;
    //  flex-direction: column !important;
    //  .orientation-message {
    //    color: $black !important;
    //  }
    //
    //  @media screen and (max-width:767px) and (orientation: portrait) {
    //    display: flex !important;
    //  }
    //
    //  &.active {
    //    display: flex;
    //  }

}
