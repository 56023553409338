.delivery-list{
  .group-add{
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-start;
  }
  .group-add-title{
    svg{@include mr(3); font-size: 1.8em;}
  }

}

.group-addlist{
  @extend .w-100;
  @extend .mt-3;
  ul{background: none;
    li{background: $white; border-bottom: 2px solid $grey-bright; svg{@include mr(2); max-width: 24px; @extend .w-100;}}
    text-align: left;
  }
}

.darkTheme{
  .deliveries-content .group-addlist {
    ul {
      background: none;
      li {
        background: $white;
        color: $charcoal !important;
      }
    }
  }
}

.group-form-overlay{
  display: none;
  top:0;
  left:0;
  @extend .position-relative;
  &.active{
    @extend .w-100;
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-grow-1;
    height:100%;
    @include pt(3);
    @extend .pb-3;
    @extend .mb-0;
    overflow: auto;
  }
}

.group-form-holder{
  @extend .w-100;
  height: auto;
  margin: 1em 0;
  @include ctm-breakpoint(768px){
    max-width: 95%;
  }
}

#iPad .group-form-holder{
  max-width: 986px;
}

.group-form-content{
  width: 100%;
  background: $white;
}

.group-form-top{
  @extend .bg-primary;
  @extend .w-100;
  max-width: 680px;;
  @extend .p-2;
  @include pl(4);
  @include pr(4);
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  @extend .justify-content-between;
  h2{color: $white}
  @include ctm-breakpoint(768px){
    max-width: 100%;
  }
}

#iPad .group-form-top{max-width: 100%}
#iPad   div[class^="signature"]{ flex-direction: column !important;}

.group-form-body{
  @extend .bg-grey-bright;
  @extend .pb-3;
  @include pl(3);
  @include pr(3);

  .formfields{list-style: none; margin:0; @extend .pb-3;

    &.col-row{
      .holder{ @extend .flex-row;}
      @extend .bg-grey-bright;
    }
    &.col-column{
      .holder{@extend .flex-column;}
    }
  }

  .fields-group{
    @extend .justify-content-between;
    padding:1em 0.5em;

    @extend .bg-grey-bright;
    border-top:1px solid $white;

    &.active{@extend .bg-cyan; h3{@extend .text-white;}
      .job-header h3{@extend .mb-0; @include ml(3)}
      .job-header svg{color: $yellow-dark}
    }

    &:nth-child(odd){ @extend .bg-grey-bright;  label{color: $charcoal} span{color: $charcoal}}
    // &:nth-child(even){ @extend .bg-cyan; label{color: $white} span{color: $white}}
    &:nth-child(even){ @extend .bg-grey-bright;  label{color: $charcoal} span{color: $charcoal}}
    &:first-child{border-top:0; padding-top:0px}
    &:last-child{@include mb(3); border-bottom:1px solid $white !important;}

    &.col-row{
      @extend .bg-grey-bright;
    }

    .holder{
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-between;
      max-width: 680px;;
      @extend .w-100;
      @extend .m-auto;
      .label{text-align: left; padding-left:0;}
      .field{@extend .d-flex; @extend .align-items-end; @extend .flex-column;
       input[type="text"],input[type="email"]{width: 100% !important; padding:0em 0.5em;} padding-right: 0; padding-left:0;}
      .select-css{min-width: 180px;}
    }

  }
  .submit-component{border-top:1px solid $white !important;}
}

.group-form-body .fields-group .holder{@extend .d-flex; @extend .flex-row;}
.group-form-msg{padding-top:1em;}
.fields-group.error{
  canvas.signatureCanvas{border: 2px solid $red}
  .error-message{display: flex; flex:1; justify-content: flex-end; color: $red; @include mb(3); text-align: right}
}
