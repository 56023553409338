.dark {

  /* redefine theme colors for dark theme */
  $primary: $gray-700;
  $secondary: #111111;
  $success: #222222;
  $dark: #000;
  $body-color: #eeeeee;
  $body-bg: $gray-900;

  $theme-colors: (
          "primary": $primary,
          "secondary": $secondary,
          "success": $success,
          "danger": $danger,
          "info": $indigo,
          "dark": $dark,
          "light": $light,
          "body-bg": $body-bg,
  );

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  /* redefine theme color rgb vars (used for bg- colors) */
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }



  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  .bg-grey-dark{
    background: $black;
  }


  $list-group-active-bg: $primary;
  $pagination-active-bg: $primary;
  $pagination-color: $secondary;


  @each $state, $value in $theme-colors {
    $list-group-variant-bg: shift-color($value, $list-group-item-bg-scale);
    $list-group-variant-color: shift-color($value, $list-group-item-color-scale);
    @if (contrast-ratio($list-group-variant-bg, $list-group-variant-color) < $min-contrast-ratio) {
      $list-group-variant-color: mix($value, color-contrast($list-group-variant-bg), abs($list-group-item-color-scale));
    }

    @include list-group-item-variant($state, $list-group-variant-bg, $list-group-variant-color);
  }

  @import "bootstrap";

  .page-header {
     @extend .text-white;
  };

}