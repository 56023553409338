.no-events{
  > *{pointer-events: none}
}
.btn-def-rnd{
  border-radius: 5px !important;
  font-size: $font-size-med !important;
  appearance: none;

  &.no-border{
    border:none;
  }
  &.no-bg{
    background: none;
  }
}

.btn-def{
  appearance: none;
  &.no-border{
    border:none;
  }
  &.no-bg{
    padding:0;
    background: none;
  }
}

.btn-def,.btn-def-rnd{
  svg{font-size: 2em; @include mr(2);}
  //&:hover{text-decoration: none; cursor: pointer}
}

.btn-shadow{
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.425);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.35);
}

.btn-blue-base{
  @extend .bg-primary;
  color: $white;
  &.active{
    border: $orange-pestle 2px solid;
    color: $white;
    background: $orange-tangy;
  }
  &:not(.active):disabled {
    color: $grey-light;
    background: $blue-300;
    border: $blue-900;
  }
}

.btn-red-base{
  background: $orange-tangy;
  border: $orange-pestle 2px solid;
  color: $white;
  //&:hover {
  //  border: $blue-base 2px solid;
  //  background: $blue-dark;
  //}
  //&.active{
  //  border: $blue-base 2px solid;
  //
  //  background: $blue-dark;
  //}
}


.btn-grey-light{
  background: $grey-light;
  border: $grey-smoke 2px solid;
  color: $charcoal;
  //&:hover {
  //  border: $orange-pestle 2px solid;
  //  color: $white;
  //  background: $orange-tangy;
  //}
  //&.active{
  //  border: $orange-pestle 2px solid;
  //  color: $white;
  //  background: $orange-tangy;
  //}
}

.btn-grey-light-2{
  background: $grey-light;
  border: $grey-smoke 2px solid;
  color: $charcoal;
  &:hover {
    border: $blue-dark 2px solid;
    color: $white;
    background: $blue-dark;
  }
  &.active{
    border: $orange-pestle 2px solid;
    color: $white;
    background: $orange-tangy;
  }
}

.btn-grey-light-3{
  background: $grey-light;
  border: $grey-smoke 2px solid;
  color: $charcoal;
  &.browser:hover {
    border: $blue-dark 2px solid;
    color: $white;
    background: $blue-dark;
  }
  &.active{
    border: $blue-dark 2px solid;
    color: $white;
    background: $blue-dark;
  }
}

.btn-red{
  background: $red;
  color: $white;
}

.btn-green-dark{
  background: $green-dark;
  color: $white;
  border: $green-forest 2px solid;
  //&:hover {
  //  border: $blue-base 2px solid;
  //  background: $blue-dark;
  //}
  //&.active{
  //  border: $blue-base 2px solid;
  //
  //  background: $blue-dark;
  //}
}

.btn-green-meadow{
  background: $green-meadow;
  color: $white;
  &:hover {
    border: $blue-base 2px solid;
    background: $blue-dark;
  }
  &.active{
    border: $blue-base 2px solid;

    background: $blue-dark;
  }
}

.btn-login{
  @extend .w-100;
  max-width: 220px !important;
  margin:0 auto !important;
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  @extend .justify-content-center;
  text-align: center !important;

  @extend .btn-def-rnd;
  @extend .btn-blue-base;
  @extend .p-2;

  @include ctm-breakpoint(420px){
      color: $white;
  }
  //@extend .btn-shadow;

  .sk-fading-circle{
    @include ml(3);
  }
}

.header-app .settings-holder, .header-app .network-holder{
  .btn-settings-back,.btn-network-back{
    svg{ @extend .text-charcoal;}
    &.active{
      overflow: auto !important;
      svg{ @extend .text-charcoal}
    }
  }
}

.network-btn{ &.online{ svg{color: $green-light !important;} } }

.btn-add, .btn-logout{
  @extend .align-items-center;
  @extend .justify-content-center;
  font-size: 1.4em;
  svg{@include mr(3); @extend .mt-1; font-size: 1.2em; color: $white}
}

.btn-clear {
  @extend  .btn-red;
  max-width: 150px;

  @media screen and (max-width:600px) and (orientation: portrait)  {
    display: none !important;
  }
}

.btn-login,.btn-submit, .btn-sign-again {
  font-size: 1.4em !important;
  @extend .d-flex;
  max-width: 220px;
}

.btn-submit, .btn-sign-again, .btn-reset, .btn-clear{
  //@extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  @extend .justify-content-center;
  text-align: center;
  @extend .m-auto;

  @extend .btn-shadow;
  @extend .position-relative;
  @extend .w-100;
  @extend .p-2;
  @extend .mt-3;
  @extend .btn-def-rnd;
  &:disabled{
    @extend .bg-grey-light;
    &:hover{ @extend .bg-grey-light;   @extend .border-charcoal;}
  }
}


.btn-settings,.btn-network{
  padding:0;
  svg{margin-right: 0 !important;}
}

.btn-network-back,.btn-settings-back{
  @extend .d-flex;
  @extend .align-items-center;
  padding: 0.5em 0.6em;
  @extend .solid-2-line;
  svg{margin:0 !important;}
  @extend .border-rad-5;
  .svg-inline--fa.fa-w-14{width: auto; margin-left: 5px;}
}

.btn-logout{
  max-width: 220px;
  line-height: 0;
  svg{margin-top:0 !important; }
  @extend .btn-shadow;
}

.btn-back-to-queue{
  @extend .btn-def-rnd;
  @extend .btn-blue-base;
}
.btn-sync{
  svg{color: $white}
  @extend .btn-def-rnd;
  @extend .btn-blue-base;
  @extend .p-2;
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .m-auto;
  @extend .w-100;
  &.offline{
     border: 2px solid $grey-smoke;
     background: $grey-smoke !important;
    &:hover{
      border: 2px solid $grey-smoke;
      background: $grey-smoke !important;
    }
  }
}

.btn-base-back{
  @extend .btn-def-rnd;
  @extend .btn-blue-base;
  @extend .p-2;
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-center;
  @extend .align-items-center;
  @extend .w-100;
  @include mb(3);
}

.btn-camera{
    @extend .btn-def-rnd;
    //@extend .d-flex;
    @extend .flex-row;
    @extend .align-items-center;
    @extend .border-charcoal;
    @extend .p-1;
    @extend .bg-primary;
    @extend .text-white;
    @include mt(3);
    @extend .border-charcoal;
    svg{ font-size: 1.5em !important;}

  //@extend .no-events;

    &:disabled{  @extend .bg-grey-pale; @extend .border-charcoal; color: $grey-smoke !important; svg{color: $grey-smoke !important;}
  }
  //.sk-fading-circle{
  //  max-width: 12px;
  //  max-height: 12px;
  //  font-size: 0.8em;
  //}
}

.btn-add-image{@extend .btn-camera; @include mr(3);}

.lineitem-btn-inputs{
  .btn-camera{@extend .position-relative; margin-top: 0 !important; svg{margin-right: 0 !important; font-size:1.2rem;
    .sk-fading-circle{
      max-width: 12px;
      max-height: 12px;
      font-size: 0.8em !important;
      color: $charcoal !important;
    }
  }
    svg.fa-ban{@extend .position-absolute; color: $red !important; font-size: 1.5em; left:5px; &:hover{color: $red !important;}}
  }

  .webcam.lineitem{
    .window-camera{
    .btn-camera{margin-top:1em !important;
      svg{font-size:2.0rem; margin-right: 0.5em !important;}
    }}
  }
}
.btn-safe{
  @extend .d-flex;
  @extend .position-relative;
  @extend .btn-def-rnd;
  @extend  .btn-green-dark;
  padding: 0.5em;
  span{@extend .position-relative;}
  &:disabled{
    background: rgba($green-dark,0.7);
  }
  &:hover{border:2px solid $green-forest;}
}

.btn-unsafe{
  @extend  .btn-def-rnd;
  @extend .btn-red;
  padding: 0.5em;
  &:disabled{
    background: rgba($red,0.7);
  }
}

.btn-sort{
  @include ml(3);
  @extend .btn-grey-light-3;
  @extend .btn-def-rnd;
  @extend .d-flex;
  @extend .p-2;
  @extend .align-items-center;
  svg {margin-right:0.5em !important; color: $white; font-size: 1em}
  @extend .btn-shadow;
}

.btn-sign,.btn-phone,.btn-location,.btn-message,.btn-camera-delivery, .btn-item-edit{
  padding:1.4em 1.4em;
  @extend .position-relative;
  @extend .border-rad-5;
  svg {@extend .position-absolute; margin-right:0 !important; color: $white}
  @extend .btn-shadow;
}

.btn-sign{
 svg{top:10px; left: 13px;}
}

.btn-phone{
  @include mr(3);
  @extend .border-circle;
  svg{top:12px; left: 12px;}
}
.btn-location{
  svg{top:12px; left: 10px;}
}

.btn-message{
  svg{top:11px; left: 12px;}
}

.btn-camera-delivery{
  svg{top:11px; left: 11px;}
}

@include ctm-breakpoint(320px){
  .btn-sign{
    svg{top:8px; left: 8px;}
  }
  .btn-phone{
    margin-right: 0.2em !important;
    padding: 1.2em 1.2em !important;
    svg{top:8px; left: 8px;}
  }
  .btn-location{
    svg{top:8px; left: 8px;}
  }
  .btn-message{
    svg{top:8px; left: 8px;}
  }
  .btn-camera-delivery{
    svg{top:8px; left: 8px;}
  }
}

.btn-back-deliveries{

  @extend .btn-blue-base;
  @extend .p-2;
  @extend .btn-shadow;
  svg{@extend .text-white;}
  @include ctm-breakpoint(480px){
    svg{margin-right: 0 !important;}
  }
}

.btn-staticmap-preview, .btn-pdf-preview{
  @extend .btn-blue-base;
  @extend .p-2;

  border-radius: 5px;
  svg{@extend .text-white; @include mr(3)}
  @extend .mt-2;
  @extend .mb-2;
  @extend .btn-shadow;
  @include ctm-breakpoint(360px){
    span{display: none}
    svg{font-size:1.4em; margin-right: 0px !important;}
  }
}

.btn-back-deliveries{
  max-width: 220px;
  @extend .btn-def-rnd;
}

.btn-lineitems{
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-between;
  @extend .bg-primary;
  color:#fff;
  border:none;
  width: 100%;

  span{margin:0px !important; padding:0; width: auto; @extend .d-flex; @extend .align-items-start; font-size: 1.75rem}
  svg{@extend .d-flex;@extend .align-items-end; font-size: 2.5em;
    &.fa-chevron-down{
     path{ @extend .btn-shadow;}
    }
  }
  @include pt(2);
  @include pb(2);
  @include pl(3);
  @include pr(3);

  @include media-breakpoint-up(md) {
    @include p(3);
  }

}

.btn-pdf-sign{
  @extend .d-flex;
  @extend .flex-row;
  @extend .justify-content-between;
  border: 0px !important;
  @extend .btn-blue-base;
  @extend .align-items-center;
  svg{@extend .text-white; font-size: 2.5rem;}
  @extend .w-100;
  @extend .mt-3;
  font-size: 1.75rem;
  @include pt(2);
  @include pb(2);
  @include pl(3);
  @include pr(3);
  @include mb(3);


  @include media-breakpoint-up(md) {
    @include p(3);
  }

  &.active{
    @extend .bg-primary;
    margin-bottom:0px !important;
  }

  span{font-weight: 500; font-size: 2.0rem;}

    background: $primary;
    margin-bottom: 1em;

    &.active{
      background: $orange-tangy;
    }

    span{font-weight: normal; font-size: 1.75rem;}

  @include media-breakpoint-up(md) {
    margin-bottom:0px !important;
  }
}


.btn-sig-comp{
  @extend .btn-blue-base;
  @extend .d-flex;
  @extend .flex-row;
  width: 100%;
  color:#fff;
  border:none;
  @include pt(2);
  @extend .pb-2;
  @include pl(3);
  @include pr(3);

  @extend .justify-content-between;
  @extend .align-items-center;
  border: 0px !important;

  svg{@extend .d-flex;@extend .align-items-end; font-size: 2.5em;}
  &.active{
    background: $blue-base;
  }

  span{font-weight: normal; font-size: 1.75rem;}

  @include ctm-breakpoint(480px){
    margin-bottom: 1em;
    &:hover{
      background: $blue-base;
    }
    &.active{
      background: $orange-tangy;
    }

    span{font-weight: normal; font-size: 1.75rem;}

  }
}

@include ctm-breakpoint(480px){
    .btn-back-deliveries{
      span{display: none}
    }

  .btn-returnjob{
    margin-top:0px;
    span{display: none}
    svg{margin-right: 0px !important;}
  }
  .btn-pdf-preview{
    span{display:none;}
    svg{margin-right: 0px !important;}
    width:100%;
    min-width: 65px;
  }
}

@include ctm-breakpoint(320px){
  .btn-returnjob{
    margin-left:0px !important;
  }
}

.btn-lineitems{ @extend .w-100; @extend .align-items-center; }
.line-items{
  @media (orientation: portrait) {
    &.mobile{margin-bottom: 1em !important;}
  }

  &.active{
    .btn-lineitems{
      color: $white;
      background: $orange-tangy;
    }
  }
}


.btn-returnjob{
  @extend .p-2;
  @extend .btn-def-rnd;
  @extend .btn-blue-base;
  @extend .align-items-center;
  svg{@extend .text-white; margin-right:0 !important;}

  @include ctm-breakpoint(320px){
    margin-left: 1em;
  }

  @extend .btn-shadow;
}

.btn-showmap{
  @extend .btn-def-rnd;
  @extend .btn-blue-base;
  @extend .align-items-center;
  @extend .p-2;
  svg{@extend .text-white}
  @extend .btn-shadow;
  @include ctm-breakpoint(480px){
    span{display: none}
    svg{margin-right: 0px !important;}
  }
}

.btn-back-deliveries,.btn-returnjob,.btn-pdf-preview{svg{@extend .text-white; font-size: 2.5em}}

.pdf-form-holder .btn-submit, .group-form .btn-submit{
  @extend .btn-blue-base;
}

.pdf-form-holder .btn-sign-again, .group-form .btn-sign-again{
  @extend .btn-green-dark;
}

.btn-close-camera{
  @extend .position-absolute;
  @extend .btn-grey-light;
  top:-24px;
  right:-20px;
  font-size: 1.3em;
  padding: 0.2em 0.6em;
  border-radius: 5px;
  @extend .no-events;

  &:hover{
    svg{ @extend .text-white;}
    background: $blue-base;
    @extend .text-white;
    @extend .border-blue-dark;
  }

  @include ctm-breakpoint(376px){
    top:-18px;
    right: -12px;
  }

  @include ctm-breakpoint(360px){
    top:-18px;
    right: -12px;
  }

  @include ctm-breakpoint(320px){
    top:-18px;
    right: -12px;
  }
}

.btn-close-camera.lineitem{
  @extend .position-absolute;
  top: 0px;
  right: 0px;
}

.btn-select{
  @extend .btn-def-rnd;
  @extend .btn-blue-base;
  @extend .align-items-center;
  padding:0.5em 0.5em;
  svg{font-size: 1.2em; color:$white}
}

.btn-returnjob{
  span{@include ml(3);}
}

.btn-item-edit{
  @extend .position-relative;
  padding: 1.0em;
  @extend .btn-blue-base;
  border-radius: 5px;
  svg{top: 5px; left: 7px; font-size: 1.2em;}

  @extend .btn-shadow;
}

.btn-pagination-prev,.btn-pagination-next{
  @extend .position-relative;
  @extend .btn-blue-base;
  border-radius: 5px;
  padding:1.2em;
  z-index: 2;

  @extend .btn-shadow;

  svg{@extend .position-absolute; font-size: 1.2em; z-index: 1;}
}

.btn-pagination-prev{
  svg{top: 10px; left: 30%}
}
.btn-pagination-next{
  svg{top: 10px; left: 40%}
}

.btn-lineadd,.btn-lineminus{
  position: relative;
  border:0px;
  margin:2px 5px;
  height: auto;
  @extend .btn-blue-base;
  span{@include ml(2);}
  color: $white;
  border: 2px solid $blue-dark;
  border-radius: 5px !important;
  padding:0.3em 0.6em;
  z-index: 2;
  svg{position: relative;  color: $white; z-index: 1}
  &.no-border{
    border-radius: 0px !important;
    svg{font-size: 1.0em; color: $grey-pestal}
    &:hover, &:focus{border-radius: 0px !important;}
  }
  &.disabled{
    @extend .btn-grey-light;
  }
  @extend .btn-shadow;
}

.btn-lineadd-more{
  @extend .btn-blue-base;
  @extend .border-circle;
  @extend .p-3;
}

.btn-lineminus-more{
  @extend .btn-red-base;
  @extend .border-circle;
  @include ml(2);
  @extend .p-3;
}

.btn-linecheck{
  @extend .position-relative;
  @extend .btn-green-dark;

  @include ml(2);
  @extend .p-3;
  border-radius:5px ;
  @extend .no-events;
  svg{color: $white; font-size: 1.0em; @extend .position-absolute; top:10px; left:8px;}
  appearance: none;
  -webkit-appearance: none;
  @extend .btn-shadow;
  @include ctm-breakpoint(731px){
    margin-right: 0px !important;
  }
  .sk-fading-circle{
    max-width: 12px;
    max-height: 12px;
    font-size: 0.8em;
  }

  @include ctm-breakpoint(480px){
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}

.btn-confirm,.btn-cross{
  @extend .d-flex;
  @extend .flex-row;
  @extend .position-relative;

  @extend .btn-def-rnd;
  @extend .text-white;
  @extend .align-items-center;
  @include mr(3);
  padding:0.5em 1em;
  svg{color: $white;}
  appearance: none;
  -webkit-appearance: none;
  margin-left: 0.2em !important;
  margin-right: 0.2em !important;
}

.btn-confirm{
  @extend .btn-green-dark;
  svg{ font-size: 1.4em; vertical-align:-5px;}
}

.btn-cross{
  @extend .btn-red-base;
  svg{ font-size: 1.6em;}
}

.btn-group-sign{
  @extend .p-2;
  @extend .btn-def-rnd;
  @extend .btn-blue-base;
  svg{ color:$white; font-size: 1.4em;}
  @extend .d-flex;
  @extend .align-items-center;
  margin:1em auto 0;
  @extend .w-100;
}

.btn-close-group{
  @extend .border-circle;
  @extend .btn-def;
  @extend .bg-grey-light;
  @extend .btn-network-back;
}

.btn-delete-img{
  @extend .position-absolute;
  right:-12px;
  top:-20px;
  @extend .btn-red-base;
  font-size: 1.4em;
  padding:0.2em 0.5em;
  border-radius: 5px;
  border:2px solid $crimson;
  @extend .no-events;

}

.btn-veh-back{
  @extend .d-flex;
  @extend .flex-row;
  @extend .align-items-center;
  padding:0.5em 1em;
  margin: 0 auto;
  svg{font-size: 1.4em; color: $white}
  span:last-child{@include ml(3);}
}

.btn-close-staticmap,.btn-close-fullpdf{
  @extend .position-absolute;
  top:-18px;
  right:-32px;
  padding:0.3em 0.6em;
  margin-bottom:1em;
  z-index: 5;
  border-radius: 5px;
  svg{margin:0 !important; font-size:1.4em }
  @include ctm-breakpoint(420px){
    top:-5px;
    right:5px;
  }
}

button .hidden{display: none}
.btn-group-sign{ @extend .justify-content-between}

.btn-line-product{
  @extend .d-flex;
  width: 100%;
  border:0;
  text-align: left;
  padding-top:0.5em;
  padding-bottom:0.5em;
  @extend .align-items-center;
  @extend .justify-content-between;
  padding-left:1em;
  padding-right:1em;
  @extend .no-events;
  svg{color: $blue-base}
  .fa-chevron-down{display: none}
  &.active{
    width: 100%;
    background: $orange-tangy !important;
    color: $white;
    .fa-chevron-right{display: none}
    .fa-chevron-down{display: flex; color: $white}
  }
  &.actioned {
    background: $orange-light;
  }

}

.btn-pic{
  position: absolute;
  @extend .d-flex;
  @extend .align-items-center;
  min-width: 120px;
  padding: 1em;
  bottom:0px;
  z-index: 6;
  svg{margin-right: 1em;}
}
