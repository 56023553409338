/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

/**
############ Colors ################
**/
$white:#FFFFFF;
$blue-base: #4A86E8;
$blue-dark: #233B64;
$grey-bright: #F2F2F2;
$grey-white:#EFEFEF;
$cyan:#6F9FEE;
$blue-sky:#4bb0dc;
$violet:#89A6D6;
$charcoal: #404040;
$grey-dark:#343A40;
$grey-pestal:#545557;
$grey-paste:#607d8b;
$grey-smoke:#666666;
$grey-light:#D9D9D9;
$grey-pale:#E0E2E5;
$black:#000000;
$red-warning:#FC361D;
$red:#FC361D;
$yellow-dark:#FFA96B;
$yellow:#F9AB01;
$orange-light:#FFBC8C;
$orange-pestle:#CC5B23;
$orange-tangy:#CC5139;
$crimson:#9e412e;
$jade:#00A86B;
$green:#28A745;
$green-light:#00FF00;
$green-dark:#19653F;
$green-meadow:#218553;
$green-forest:#0F4028;
$light-cyan:#89A6D6;
/*------------------------------------*\
    $TYPOGRAPHY
\*------------------------------------*/

$font : "Roboto", sans-serif;
$font-size-small : 0.75rem;
$font-size-small-2 : 0.875rem;
$font-size-med : 1rem;
$font-size-med-2: 1.0625rem;
$font-size-med-3: 1.2rem;
$font-size-large : 1.4375rem;

/*------------------------------------*\
    $MARGIN /PADDING / SPACING
\*------------------------------------*/

$space : 1em;
$space-and-half : $space*1.5;
$space-double : $space*2;
$space-quad : $space*4;
$space-half : $space/2;
$space-quar:$space/4;

/*------------------------------------*\
    Breakpoints
\*------------------------------------*/

$breakpoints: (
  'mobile': 767px,
  'tablet': 768px,
) !default;


$tny_breakpoint: 320px;
// Tiny Screen, Phone Portrait...
$tny_breakpoint_inner: $tny_breakpoint - 1;
$tny_breakpoint_outer: $tny_breakpoint + 1;

$sml_breakpoint: 480px;
// Small Screen, Phone Landscape...
$sml_breakpoint_inner: $sml_breakpoint - 1;
$sml_breakpoint_outer: $sml_breakpoint + 1;

$med_breakpoint: 768px;
// Medium Screen, Large Phone/Tablet...
$med_breakpoint_inner: $med_breakpoint - 1;
$med_breakpoint_outer: $med_breakpoint + 1;

$lrg_breakpoint: 980px;
// Large Screen, Laptop/Desktop...
$lrg_breakpoint_inner: $lrg_breakpoint - 1;
$lrg_breakpoint_outer: $lrg_breakpoint + 1;

$xlg_breakpoint: 1024px;
// Extra Large Screen, Desktop...
$xlg_breakpoint_inner: $xlg_breakpoint - 1;
$xlg_breakpoint_outer: $xlg_breakpoint + 1;


/*------------------------------------*\
    Inputs/ Webkit elements
\*------------------------------------*/

$track-color: $grey-bright !default;
$thumb-color: $grey-light !default;

$thumb-radius: 0px !default;
$thumb-height: 22px !default;
$thumb-width: 12px !default;
$thumb-shadow-size: 4px !default;
$thumb-shadow-blur: 4px !default;
$thumb-shadow-color: rgba(0, 0, 0, .1) !default;
$thumb-border-width: 2px !default;
$thumb-border-color: $grey-smoke !default;

$track-width: 100% !default;
$track-height: 8px !default;
$track-shadow-size: 1px !default;
$track-shadow-blur: 1px !default;
$track-shadow-color: rgba(0, 0, 0, .2) !default;
$track-border-width: 2px !default;
$track-border-color: $grey-smoke !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken($track-color, $contrast) !default;
