
/*------------------------------------*\
    $ANIMATION
\*------------------------------------*/

@keyframes slideOutUp {
  from {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -110%, 0);
  }

}

@keyframes slideIn {
  0% {
    top:100%;
    height: 0;
  }
  100% {
    top:0;
    height: 100%;
  }
}

@keyframes slideOut {
  0% {
    top:0;
    height: 100%;
  }
  100% {
    display: none;
    top:100%;
    height: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  50% {
     opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
}

@keyframes popUpSlideIn {
  0% {
    top:0%;
  }
  100% {
    top:25%;

  }
}
