/*------------------------------------*\
    $BORDERS
\*------------------------------------*/

.border-rnd{
  border-radius: 5px;
}
.border-rnd-top{
  border-radius: 5px 5px 0 0;
}

.border-circle{
  border-radius: 50% 50%;
}

.border-rad-5{
  border-radius: 5px;
}
.solid-1-line{
  border: 1px solid;
}

.solid-2-line{
  border: 2px solid;
}

.border-charcoal{
  border-color: $charcoal;
}

.border-blue-dark{
  border-color: $blue-dark;
}

.border-blue-sky{
  border-color:$blue-sky;
}

.border-white{
  border-color:$white;
}

.border-red-warning{
  border-color: $red-warning;
}

.border-green-forest{
  border-color: $green-forest;
}

.border-blue-sky{
  border-color: $blue-sky;
}

.border-top{
  border-top:1px solid;
}

.border-yellow-dark{
  border-color: $yellow-dark;
}