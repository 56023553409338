.line-items {
  @include make-row()
  @extend .border-white;
  border-bottom: 1px solid;
  margin-right: 0px !important;
  margin-left: 0px !important;


  .lineitems-content{
    @include make-col(12);
    display: none;
    margin-right: 0px !important;
    margin-left: 0px !important;

    &.active {
      display: block;
    }

    .item-line {
      padding: 0rem 0rem 0.5rem 0rem !important;

      &.actioned{
        background: $orange-light !important;
        .actioned-tick{color: $green-meadow}
        .item-line-top{
        }
      }
    }

    .line-item-titles {
      @extend .d-tablet-flex;
      color: $white;
      background: $light-cyan;
    }

    .line-item-elements {
      @include make-row();
      @extend .w-100;
      @extend .align-items-start;
      @include pt(3);
      @include pb(3);
      @include pl(3);
      margin-right: 0px !important;
      margin-left: 0px !important;
      div{  text-align: left;}


       .modified-indicator {
          @include make-col(1);
          @extend .text-center;
          .actioned-tick{
            color: $green-meadow
          }
        }

        .product-title{
          @include make-col-ready();

          @include media-breakpoint-down(sm) {
            @include make-col(12);
          }
          @include media-breakpoint-up(md) {
            @include make-col(2);
          }

          overflow-wrap: break-word;
        }
        .product-desc{
          @include media-breakpoint-down(sm) {
            @include make-col(6);
          }
          @include media-breakpoint-up(md) {
            @include make-col(5);
          }
        }
        .product-quantity{
          @include make-col(2);
        }
        .product-actions{
          @include media-breakpoint-down(sm) {
            @include make-col(4);
          }
          @include media-breakpoint-up(md) {
            @include make-col(2);
          }
        }

    }

    .line-item-content {
      text-align: left;
      display: none;
      width: 100%;
      @extend .flex-column;
      align-items: flex-start !important;

      h5 {
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0.2em;
        padding-bottom: 0.2em;
        font-weight: normal;
        font-size: 1.1em;
        background: $violet;
        color: $white;
        width: 100%;
        margin-bottom: 0.5em;
      }

      &.active {
        display: flex
      }

      .line-item-desc, .quantity-holder, .btn-holder {
        width: 100%;
        margin-bottom: 1em;

        span {
          display: flex;
          padding: 0 1em;
        }
      }

    }
    //          .message-container h5{background: none !important;}
    //
    //
    //
    //
    //        .btn-item-edit {
    //          margin-left: 1em;
    //          width: 38px;
    //          height: 38px;
    //        }
    //
    //
    //        .btn-linecheck{@include mr(3);}
    //        &.actioned{ background: $orange-light !important;
    //          .actioned-tick{color: $green-meadow}
    //          .item-line-top{}
    //        }
    //      }
    //    }
    //  }
    //}
    //

    .range-triangle{
                border-left: 40px solid transparent;
                border-right: 40px solid transparent;
                border-top: 20px solid $white;
                &.actioned{
                  border-left: 40px solid transparent;
                  border-right: 40px solid transparent;
                  border-top: 20px solid $orange-light;
                }
              }


    .range-container {
      display: none;

      &.active {
        display: flex;
      }
    }


  }
}


///*
//.line-items{
//  @extend .border-white;
//  border-bottom: 1px solid;
//  &.mobile{@include mb(3)}
//}
//
//.darkTheme .line-items{
//  @extend .bg-grey-smoke;
//  .range-counter{@extend .text-charcoal}
//}
//
//.lineitems-content{
//  display: none;
//  @extend .flex-column;
//  @extend .align-items-center;
//  @extend .justify-content-between;
//  &.active{@extend .d-flex;}
//
//  ul {@extend .d-flex; @extend .w-100; @extend .flex-column;}
//  ul li{@extend .d-flex; @extend .flex-column;
//    .lineitem-top{@extend .d-flex; @extend .flex-row; @extend .text-left; @extend .align-items-center;
//      p{ display: flex; margin-bottom: 0}
//      @extend .justify-content-between;}
//    .item-qty{text-align: left;}
//  }
//
//}
//
//.deliveries-content{
//  .pagination {
//    @extend .d-flex;
//    @extend .w-100;
//    @extend .border-white;
//    @extend .align-items-center;
//    @include pr(3);
//    @include pl(3);
//    border-top: 1px solid;
//    @include ctm-breakpoint(420px){
//      padding-left: 0 !important;
//      padding-right: 0 !important;
//    }
//    ul {
//      @extend .d-flex;
//      @extend .flex-row;
//      border: $blue-dark 2px solid;
//      width: auto !important;
//      margin: 1em auto;
//      -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
//      -moz-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.425);
//      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.35);
//
//      li {
//        @extend .p-0;
//        border: none;
//      }
//
//      @include ctm-breakpoint(420px) {
//        margin: 1em auto;
//      }
//    }
//
//  }
//}
//
//.pagination{ ul li{a{border-radius: 0 !important;}}}
//
//.darkTheme .deliveries-content .pagination ul li {
//  a{@extend .text-charcoal;}
//  &.active a{@extend .text-white;}
//}
//
//.darkTheme .deliveries-content .pagination{border-top:2px solid $charcoal !important; border-bottom:2px solid $charcoal !important;}
//.deliveries-content {
//  ul {
//    li {
//      &.item-line {
//        @extend .d-flex;
//        @extend .flex-column;
//        @extend .align-items-start;
//        @extend .justify-content-between;
//
//        &.mobile {
//          padding: 0 !important;
//          &:first-child{border-top: 0}
//          &:nth-child(odd) {
//            .btn-line-product {
//              background: $white;
//              &.actioned{
//                background: $orange-light;
//              }
//            }
//          }
//
//          &:nth-child(even) {
//            background: $grey-bright;
//            .btn-line-product {
//              background: $grey-bright;
//              &.actioned{
//                background: $orange-light;
//              }
//            }
//          }
//          .btn-line-product {
//            &.actioned{
//              background: $orange-light;
//            }
//          }
//          border-top:1px solid $charcoal;
//          &:last-child{ border-bottom:1px solid $charcoal;}
//        }
//
//        .line-item-content {
//          text-align: left;
//          display: none;
//          width: 100%;
//          @extend .flex-column;
//          align-items: flex-start !important;
//
//          h5 {
//            padding-left: 1em;
//            padding-right: 1em;
//            padding-top:0.2em;
//            padding-bottom:0.2em;
//            font-weight: normal;
//            font-size: 1.1em;
//            background: $violet;
//            color: $white;
//            width: 100%;
//            margin-bottom: 0.5em;
//          }
//          .message-container h5{background: none !important;}
//          &.active {
//            display: flex
//          }
//
//          .line-item-desc, .quantity-holder, .btn-holder {
//            width: 100%;
//            margin-bottom: 1em;
//
//            span {
//              display: flex;
//              padding: 0 1em;
//            }
//          }
//        }
//
//        .btn-item-edit {
//          margin-left: 1em;
//          width: 38px;
//          height: 38px;
//        }
//
//
//        .btn-linecheck{@include mr(3);}
//        &.actioned{ background: $orange-light !important;
//          .actioned-tick{color: $green-meadow}
//          .item-line-top{}
//        }
//      }
//    }
//  }
//}
//
.range-triangle{
  position: absolute;
  z-index: 2;
  width: 0;
  height: 0;
  top:0;
  right: 35px;
}
//
//.lineitems-content{
//  ul{ background: $white;
//    li{
//      padding: 0.5em 1em !important;
//      &:nth-child(odd){
//        background: $white;
//        .range-triangle{
//          border-left: 40px solid transparent;
//          border-right: 40px solid transparent;
//          border-top: 20px solid $white;
//          &.actioned{
//            border-left: 40px solid transparent;
//            border-right: 40px solid transparent;
//            border-top: 20px solid $orange-light;
//          }
//        }
//
//      }
//      &:nth-child(even){
//        background: $grey-bright;
//        .range-triangle{
//          border-left: 40px solid transparent;
//          border-right: 40px solid transparent;
//          border-top: 20px solid $grey-bright;
//          &.actioned{
//            border-left: 40px solid transparent;
//            border-right: 40px solid transparent;
//            border-top: 20px solid $orange-light;
//          }
//        }
//      }
//    }
//  }
//}
//
//
//
//.darkTheme .lineitems-content{
//  ul{ background: $white;
//    li{
//      padding: 0.5em 1em 0.8em !important;
//      &:nth-child(odd){
//        background: $grey-smoke;
//        .range-triangle{
//          border-left: 30px solid transparent;
//          border-right: 30px solid transparent;
//          border-top: 20px solid $grey-smoke;
//        }
//      }
//      &:nth-child(even){
//        background: $grey-dark;
//        .range-triangle{
//          border-left: 30px solid transparent;
//          border-right: 30px solid transparent;
//          border-top: 20px solid $grey-dark;
//        }
//      }
//    }
//  }
//}
//
//.line-item-titles{
//  @include make-row();
//  @extend .w-100;
//  @extend .align-items-start;
//  @include pt(3);
//  @include pb(3);
//  @include pl(3);
//  color: $white;
//  background: $light-cyan;
//  div{  text-align: left;}
//  .product-title{
//    @include make-col(3);
//  }
//  .product-desc{
//    @include make-col(5);
//  }
//  .product-quantity{
//    @include make-col(2);
//  }
//  .product-actions{
//    @include make-col(2);
//  }
//  @include ctm-breakpoint(480px){
//    display: none !important;
//  }
//}
//
//.item-line {
//  border-left-width: 0px !important;
//  border-right-width: 0px !important;
//}
//
//.item-line-top{
//
//  @extend .w-100;
//  @include make-row();
//  div{  text-align: left;}
//  @include pt(3);
//  @include pb(3);
//  @include pl(3);
//  @include pr(0);
//
//  .line-item-product{
//    @include make-col(3);
//  }
//  .line-item-desc{
//    @include make-col(5)
//  }
//  .quantity-holder{
//    @include make-col(2);
//    .quantity-number{@include ml(2);}
//  }
//  .btn-holder{
//    @include make-col(2);
//  }
//
//  .darkTheme .line-item-titles{
//    .product-title,.product-desc,.product-quantity, .product-actions{@extend .text-white}
//  }
//
//  .darkTheme .item-line-top {
//    .quantity-holder{@extend .text-white}
//  }
//
//  @include ctm-breakpoint(480px){
//    flex-direction: column !important;
//    align-items: start;
//    justify-content: flex-start;
//    .line-item-product{width: 100%; text-align: left; max-width: 100%; margin-left: 1em;}
//    .quantity-holder{margin:0 auto 1em; width:100% !important; max-width: 100% !important;  justify-content: flex-start; align-items: start;}
//    .btn-holder{margin:0 auto 0.5em !important;}
//  }
//}
//
//.line-items.mobile{
//  .line-item-product{max-width: 100%; display: flex; width: 100%; margin-right: 0 !important;}
//}
//
//.range-container{
//  display: none;
//  @extend .w-100;
//  &.active{@extend .d-flex; @extend .w-100; @extend .align-items-end;
//    @extend .d-flex;
//    @extend .flex-column;
//    padding:1.5em 1em 1em;
//    @extend .mt-3;
//    background: $grey-pale;
//    @extend .position-relative;
//  }
//  select{padding: 0.2em 0.5em;   -webkit-appearance: none;}
//  .btn-holder{@extend .align-items-center; @extend .d-flex; @extend .justify-content-between;}
//
//  .image-preview{
//    @extend .position-relative; margin:1.5em auto; padding-left:0.5em; padding-right:0.5em;
//    img{width: auto;
//      height: auto;
//      max-width: 100%;}
//  }
//  @media screen and (orientation: landscape) {
//    .image-preview{ margin:1em auto 1em;
//
//      img{width: 100%;
//        max-width: 100%;}
//    }
//  }
//  @include ctm-breakpoint(480px){
//    .image-preview{ margin:1em auto 1em;
//      padding:0;
//    }
//  }
//
//  .btn-camera{
//    @extend .btn-item-edit;
//    width: 38px;
//    height: 38px;
//    .sk-fading-circle{color: $charcoal !important;}
//  }
//
//  .btn-delete-img{
//
//    width: 38px;
//    height: 38px;
//    right: -10px;
//    top: -16px;
//
//    padding:0.1em 0.4em;
//    @extend .text-white;
//    svg{ position:absolute; top: 8px; left: 25%; font-size: 0.8em;}
//    .sk-fading-circle{width:12px; height:25px; margin-top:2px; margin-left:2px;}
//  }
//
//
//}
//
//
//
//.range-input{@extend .align-items-center; @extend .justify-content-between; @extend .w-100; max-width: 220px;}

.add-lineitem{
  @include make-row()
  @extend .w-100;
  justify-content: flex-end !important;
  background: $white; margin-top: 1em;
  &:first-child{margin-top: 0;}
  &:last-child{margin-bottom: 1em}
  .no-items{ @extend .w-100; text-align: left}
  @include pt(3);
  @extend .pb-3;
  @include pl(2);
  @include pr(2);

  &.input-holder{
    background: $grey-pale; margin-top: 0 !important;
    select{padding:0.5em 1em}
    .lineitem-btn-inputs{

      @extend .d-flex;
      @extend .flex-row;
      @extend .w-100;
      justify-content: right;

      @include ctm-breakpoint(380px){
        margin-left:0 !important;
        padding:0 !important;
      }

      @include ctm-breakpoint(480px){
        align-items: start !important;
        padding:0 !important;
        justify-content: space-between !important;
      }

    }
  }

  .modified-result {
    margin-bottom:0;
    @extend .d-flex;
    @extend .flex-row;
    @extend .align-items-center;
    svg{
      @include mr(3);
      @extend .w-100;
      max-width: 40px;
      font-size: 1.4em;
    }
  }
}

//
//
//  .btn-edit-itemcount{
//    position: relative;
//    width: 38px;
//    height: 38px;
//    padding:0.4em 0.1em;
//    @extend .btn-item-edit;
//    font-size: 1.4em;
//    border-radius: 5px;
//    border:2px solid $crimson;
//    @extend .no-events;
//
//    svg{ position:absolute; top: 8px; left: 11px; font-size: 0.8em;     color: $white !important;}
//    .sk-fading-circle{width:12px; height:25px; margin-top:2px; margin-left:2px;}
//  }
//
//  .btn-delete-itemcount{
//      position: relative;
//    width: 38px;
//    height: 38px;
//    padding:0.1em 0.4em;
//    @extend .btn-red-base;
//    font-size: 1.4em;
//    border-radius: 5px;
//    border:2px solid $crimson;
//    @extend .no-events;
//
//    svg{ position:absolute; top: 8px; left: 11px; font-size: 0.8em;     color: $white !important;}
//    .sk-fading-circle{width:12px; height:25px; margin-top:2px; margin-left:2px;}
//  }
//
//  @include ctm-breakpoint(484px){
//
//    flex-direction: column !important;
//    justify-content: center !important;
//    align-items: center !important;
//    select{margin-bottom:1em;}
//    .range-input{margin-bottom: 1em; p{margin: 0 auto !important;}}
//    &.input-holder{ margin-bottom: 0 !important; padding:1em 0 !important;}
//
//    margin-left:0 !important;
//  }
//
//  .missing-result, .returned-result, .damaging-result, .no-items{margin-bottom:0; @extend .d-flex; @extend .flex-row; @extend .align-items-center; svg{@include mr(3); @extend .w-100; max-width: 40px; font-size: 1.4em;}}
//  .returned-result{svg{color: $blue-sky;}}
//  .missing-result{svg{color: $yellow-dark}}
//  .damaging-result{svg{color: $red-warning}}
//}
//
//.darkTheme .add-lineitem{
//  .missing-result, .returned-result, .damaging-result,.no-items{color: $charcoal;}
//}
//*/
