
///*
// * Breakpoints: for  responsive design
// * ------------------------------------------------------------------------------------------------------------------ */
@mixin tny-breakpoint($box: '') {
  @if $box == 'inner' {
    @media (max-width: $tny_breakpoint_inner) {
      @content;

    }
  } @else if $box == 'outer' {
    @media (max-width: $tny_breakpoint_outer) {
      @content;
    }
  } @else {
    @media (max-width: $tny_breakpoint) {
      @content;
    }
  }
}

@mixin sml-breakpoint($box: '') {
  @if $box == 'inner' {
    @media (max-width: $sml_breakpoint_inner) {
      @content;

    }
  } @else if $box == 'outer' {
    @media (max-width: $sml_breakpoint_outer) {
      @content;

    }
  } @else {
    @media (max-width: $sml_breakpoint) {
      @content;

    }
  }
}

@mixin med-breakpoint($box: '') {
  @if $box == 'inner' {
    @media (max-width: $med_breakpoint_inner) {
      @content;
    }
  } @else if $box == 'outer' {
    @media (max-width: $med_breakpoint_outer) {
      @content;
    }
  } @else {
    @media (max-width: $med_breakpoint) {
      @content;
    }
  }
}

@mixin lrg-breakpoint($box: '') {
  @if $box == 'inner' {
    @media (max-width: $lrg_breakpoint_inner) {
      @content;
    }
  } @else if $box == 'outer' {
    @media (max-width: $lrg_breakpoint_outer) {
      @content;
    }
  } @else {
    @media (max-width: $lrg_breakpoint) {
      @content;
    }
  }
}

@mixin xlg-breakpoint($box: '') {
  @if $box == 'inner' {
    @media (max-width: $xlg_breakpoint_inner) {
      @content;
    }
  } @else if $box == 'outer' {
    @media (max-width: $xlg_breakpoint_outer) {
      @content;
    }
  } @else {
    @media (max-width: $xlg_breakpoint) {
      @content;
    }
  }
}

// Custom Breakpoint for custom max-width breakpoints
@mixin ctm-breakpoint($bp: $xlg_breakpoint, $min_max: 'max') {
  @if $min_max == 'max' {
    @media (max-width: $bp) {
      @content;
    }
  } @else {
    @media (min-width: $bp) {
      @content;
    }
  }
}

/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value

    @if $breakpoint == "tablet" {
        @media (min-width: map-get($breakpoints, $breakpoint)) and (min-height: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @media (max-width: map-get($breakpoints, $breakpoint)) and (orientation: portrait), (max-height: map-get($breakpoints, $breakpoint)) and (orientation: landscape) {
            @content;
        }
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
