/*------------------------------------*\
    $TEXT COLOUR
\*------------------------------------*/

.text-white{
  color: $white !important;
}
.text-charcoal{
  color: $charcoal !important;
}
.text-cyan{
  color: $cyan !important;
}
.text-yellow-dark{
  color:$yellow-dark !important;
}
.text-grey-pestal{
  color: $grey-pestal;
}
/*------------------------------------*\
    $TEXT STYLE
\*------------------------------------*/

.text-bold{font-weight: 700}

.text-left{text-align: left}
.text-center{text-align: center}
.text-right{text-align: right}