.message {
  @extend .position-fixed;
  top: 25%;
  left: 50%;
  margin-left:-140px;
  max-height: 90vh;
  z-index: 12;
  width: 100%;
  max-width: 320px;

  .message-container {
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-start;
    max-width: 280px;

    .message-container-item {
      @extend .d-flex;
      @extend .position-relative;
      @extend .align-items-center;
      @extend .justify-content-between;
      @extend .flex-column;
      border: $charcoal solid 2px;
      margin: 5px 0px;
      padding:1em;
      border-radius: 4px;
      width: 100%;
      min-height: 100px;
      word-wrap: break-word;
      background-color: $black;
      box-shadow: 4px 4px 15px 2px rgba(black, 0.75);
      p{
        @extend .d-flex;
        background: $white;
        color: $charcoal;
        margin: 0 auto;
        text-align: center;
      }
      button{margin-top:1em;}
      transition: 0.2s;

      &:not(:first-child) {
        margin-top: -3rem;
      }

      &:hover ~ .message-container-item,
      &:focus-within ~ .message-container-item {
        transform: translateY(3rem);
      }

      &.info {
        border:0;
        @extend .bg-body;
        h3{color:$blue-sky}
        .btn-pop-up{
          @extend .btn-def-rnd;
          padding: 0.5em 1em;
          @extend .btn-blue-base;
        }

        .message-icon{
          @extend .border-blue-sky;
          @extend .solid-2-line;
          @extend .border-circle;
          @extend .position-relative;
          background: $white;
          color: $blue-sky;
          font-size: 2em;
          padding: 0.3em 0.5em;
        }
      }

      &.success{
        border:0;
        border-top: $green-forest solid 4px;
        border-bottom: $green-forest solid 4px;
        background: $white;
        h3{color:$green-forest; @extend .mt-3;}
        .btn-pop-up{
          @extend .btn-def-rnd;
          padding: 0.5em 1em;
          @extend .btn-blue-base;
        }
        .message-icon{
          @extend .border-green-forest;
          @extend .solid-2-line;
          @extend .border-circle;
          @extend .position-relative;
          background: $white;
          color: $green-forest;
          font-size: 2em;
          padding: 0.3em 0.55em;
        }

      }
      &.warning{
        border:0;
        border-top: $yellow solid 4px;
        border-bottom: $yellow solid 4px;
        background: $white;
        h3{color:$yellow; @extend .mt-3;}
        .btn-pop-up{
          @extend .btn-def-rnd;
          padding: 0.5em 1em;
          @extend .btn-blue-base;
        }
        .message-icon{
          @extend .border-yellow-dark;
          @extend .solid-2-line;
          @extend .border-circle;
          @extend .position-relative;
          background: $white;
          color: $yellow;
          font-size: 2em;
          padding: 1.2em;
          svg{position: absolute; top: 20px; left: 20px;}
        }
      }
      &.danger,&.error {
        border:0;
        border-top: $red-warning solid 4px;
        border-bottom: $red-warning solid 4px;
         background: $white;
        .btn-pop-up{
          @extend .btn-def-rnd;
          padding: 0.5em 1em;
          @extend .btn-red-base;
        }
        h3{color:$red-warning; @extend .mt-3;}
        .message-icon{
          @extend .border-red-warning;
          @extend .solid-2-line;
          @extend .border-circle;
          @extend .position-relative;
          background: $white;
          color: $red-warning;
          font-size: 2em;
          padding: 0.1em 0.5em;
        }
      }
    }

    .message-close {
      @extend .position-absolute;
      cursor: pointer;
      top: 2px;
      right:10px;
      font-size: 1.2em;
      font-weight: 800;
      color: $grey-smoke;
    }

  }
}

.job-return.message, .photo-return.message{
  display: none;
  h3{@extend .d-block; @extend .text-center; @extend .w-100;}
  .message-container{@extend .w-100;}
  .message-container-item{@extend .d-flex; @extend .flex-column;}
  &.active{@extend .d-flex;
  }
}

.job-overlay, .photo-overlay{
  @extend .overlay;
  animation: none !important;
}

.msg-alert{
  @extend .position-absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding:1em;
  text-align: center;
  background: $white;
  border-bottom: 2px solid $charcoal;
  button{margin-left: 1em}
}

.msg-image-limit{
  @extend .position-absolute;
  top:1rem;
  left:1.5rem;
}
