.window-camera{
  display: none;
  @extend .position-fixed;
  @extend .w-100;
  height: 200%;
  z-index: 4;
  &.active{
    @extend .d-flex;
    @extend .flex-column;
    top:0;
    background: rgba($black, 0.6);
  }
}

.photo-input{

  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;
  @extend .justify-content-center;

  .window-camera-body{max-width: 640px; min-height: 620px;
    .btn-close-camera{
      z-index: 6;
      top:46px;
      right: -20px;
    }
  }

  .btn-pic.btn-camera{
    margin-top: 20px;
  }
  video{width: 100%; height: 100%}
  .window-camera-body.iPad{

  }
  .camera-notification{position: relative; background: $white; color: $charcoal;
    padding:2em 1em;
    .btn-close-camera{top:-24px}
  }

  &.is-invalid {
    .btn-camera {
      border-color: $red;
      color: $red;
      svg {
        color: $red;
      }
    }
  }
}

.webcam.lineitem .window-camera.active{right:0;}

.window-camera .window-camera-body{
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .w-50;
  @extend .m-auto;
  @extend .position-relative;
  top:-25%;

  &.lineitem{
    width: 100% !important;
  }
  @include ctm-breakpoint(1024px){
    width: 70% !important;
    @media screen and (orientation: landscape) {
      width: 45% !important;
      .btn-holder{margin-top: 1em; margin-bottom: 0 !important;}
    }
  }


  @include ctm-breakpoint(768px){
    width: 90% !important;
    top:-24%;
  }

  @include ctm-breakpoint(680px){
    position: absolute;
    top:-25%;
  }

  @include ctm-breakpoint(480px){
    top:-25%;
  }

  @include ctm-breakpoint(460px){
    top:-25%;
  }

  @include ctm-breakpoint(411px){
    top:-25%;
  }

  @include ctm-breakpoint(360px){
    top:-25%;
  }

  @include ctm-breakpoint(320px){
    top:-25%;
  }

  .btn-holder{
    @extend .d-flex;
    @extend .flex-row;
  }

  @media screen and(orientation: landscape) {
    .btn-holder{display:flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1024px) and(orientation: landscape) {
    .btn-holder{display:flex;
      align-items: center;
      justify-content: center;
      margin:1em auto 0;
    }
  }


  &.iPad{
    @media (max-width: 1024px) and (orientation: landscape) {
     width: 78% !important;

    }
    @media(max-width: 768px) and (orientation: portrait) {
      width: 80% !important;
    }
  }

}

.window-camera-body.lineitem{
  flex-direction: column;
  @extend .position-relative;
  >div{
    position: absolute;
    display: flex;
    flex-direction: column;
    video{
      display: flex;
      max-width: 640px;
      max-height: 480px;
    }
  }

  .btn-camera{
    position: relative !important;
    margin:0 auto;
  }

  .camera-notification{
    padding: 2em;
    background: $white;
    @extend .text-center;
  }
}

.window-camera-body.lineitem{
  .btn-close-camera.lineitem{
    top:-20px;
    right:-20px;
  }
}
.window-camera-body.lineitem.ipad{



  @media(orientation: landscape) {
    > div {
      position: absolute !important;
      width: 100%;
      max-width: 640px;
      max-height: 480px;
      align-items: center;
      justify-content: center;
    }
    .btn-close-camera.lineitem{
      top:-48px;
      right:-22px;
      margin:0;
    }
    .camera-landscape{
      max-width: 640px;
      max-height: 480px;
      video{
        display: flex;
        max-width: 640px;
        max-height: 480px;
      }
    }
    .btn-pic.btn-camera{bottom:-82px}
  }

  @media(orientation: portrait) {
    >div{
      position: absolute !important;
      width: 100%;
      max-width: 640px;
      max-height: 600px;
      align-items: center;
      justify-content: center;
    }

    .camera-portrait{
      max-width: 640px;
      max-height: 480px;
      video{
        display: flex;
        max-width: 640px;
        max-height: 480px;
      }
    }

    .btn-pic.btn-camera{bottom:-82px}
    .btn-close-camera.lineitem{
      top:-20px;
      right:-18px;
      margin:0;
    }
  }

}
.window-camera-body.lineitem.mobile{

  >div{
    width: 100% !important;
    max-width: 380px;
  }

  @media(max-width: 732px) and (orientation: landscape) {
    top:-25%;
    >div {
      top: -8% !important;
      min-width: auto!important;
      min-height: auto;
    }

    .btn-pic{bottom:12px;}
  }

  @media(max-width: 826px) and (orientation: landscape) {
    top:-45%;
    width:38% !important;
    >div {
      top: 0 !important;
    }
    .btn-pic{bottom:0px;}
    .btn-close-camera.lineitem{
      top:-20px;
      right: -20px;
    }

  }



  @media(max-width: 440px) and (orientation: portrait) {
    top:-35%;
    >div {
      max-width: 350px;
      top: -8% !important;
      &.camera-notification{
        max-width: 320px;
      }
    }

    video{
      display: flex;
      width: 100% !important;
      max-width: 100%;
      max-height:100%;
    }

    .btn-close-camera.lineitem{    top: -20px; right: -18px;}
  }

  @media(max-width: 380px) and (orientation: portrait) {
    width: 90% !important;
  }

  @media(max-width: 680px) and (orientation: landscape) {
    top: -48%;
  }

  @media(max-width: 480px) and (orientation: landscape) {
    top:-46%;
    >div {
      width: 60% !important;
    }
  }

}


#delivery-form{
  .image-preview{
    position: relative;
    margin-top: 1em;

    @include ctm-breakpoint(768px){
      margin: 1em 0.5em 1em !important;
    }
  }
}
