.vehicle-content{
  .holder{
    @include mb(1);
    @include mt(1);
  }

  .field{
    @include p(3);
    @include mb(1);
    @include mr(auto);
    background: $grey-bright;
    color: $charcoal;
  }

  .webcam{background: $cyan; color: $white;  &:nth-child(even){background: $cyan; color: $white}}
  .image-preview{width: 100%; max-width: 320px;
    @extend .d-flex; @extend .flex-column; @extend .position-relative;
    @extend .mt-3;
    @include mb(3);
    background: $white; @extend .p-3;
    img{@extend .w-100; height: auto; margin:0 auto;}

    @include ctm-breakpoint(768px){
     width: 100% !important; height: auto !important;
      margin: 2em 0.5em 0 !important;
    }
  }

  .img-preview-title{ color: $blue-base; background: $white; @extend .p-1}
  .label{text-align: left; span{text-align: left}}
  .checkbox input[type="checkbox"]{width: 0px;}
  .fields{
    input { float:right; width: 100%; @extend .align-items-end;}
    textarea{width: 100%; min-height: 180px}
  }
  @include ctm-breakpoint(420px){

    .holder.textarea{
      flex-direction: column !important;
      .label{ margin-bottom:1em; text-align: left; max-width: 100% !important;}
      .fields{max-width: 100% !important;}
    }
  }
}

.darkTheme .vehicle-content li{ &:nth-child(even){background: $cyan; color: $white}}

.vehicle-form-btn-holder{
  @extend .d-flex;
  @extend .flex-row;
  @extend .flex-grow-1;
  @extend .align-items-center;
  @extend .justify-content-between;
  margin: 1rem 0.5rem 0rem 0.5rem;

}
