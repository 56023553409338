@import "mixins/_breakpoints";
@import "node_modules/text-security/text-security";
@import "~bootstrap/scss/bootstrap";
@import "mixins/boostrap_mixins";

/*------------------------------------*\
    GLOBAL DECLARATIONS & IMPORTS
\*------------------------------------*/

@import "variables";
@import "layout/backgrounds";
@import "layout/buttons";
@import "layout/borders";
@import "layout/text";
@import "layout/popup";
@import "layout/animation";
@import "layout/loader";
@import "themes/dark";

/*------------------------------------*\
    $PAGE STRUCTURE
\*------------------------------------*/
@import "layout/header";
@import "layout/main";
@import "layout/deliveries";
@import "layout/lineitems";
@import "layout/form";
@import "layout/inputrange";
@import "layout/confirm";
@import "layout/inputcheckbox_check";
@import "layout/webcam";
@import "layout/vehicle";
@import "layout/groupadd";
